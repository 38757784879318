import React, { useEffect, useRef, useState } from "react";
import {
  basicRules,
  handleBeforeUpload,
  handleImageUpload,
  QRTypes,
} from "../../helper/QRtypes";
import _ from "lodash";
import {
  Form,
  Input,
  Divider,
  Button,
  Modal,
  message,
  Spin,
  Skeleton,
  Select,
  Switch,
  Checkbox,
  Collapse,
} from "antd";
import TextForm from "./QRForms/TextForm";
import QR from "../../Assets/icons/qr.png";
import CustomQRCode from "./CustomQRCode";
// icons
import { IoIosArrowBack } from "react-icons/io";

import { useNavigate, useLocation } from "react-router-dom";
import WhatsApp from "./QRForms/WhatsApp";
import LinkForm from "./QRForms/LinkForm";
import BusinessForm from "./QRForms/BusinessForm";
import {
  errorNotification,
  successNotification,
} from "../../Components/Notification/notify";
import config from "../../Config/Server/Server";
import {
  saveQRInstant,
  saveVcardInstant,
  updateQRInstant,
} from "../../Config/Quries/Vacrd/VcardQuery";
import { useSelector } from "react-redux";
import moment from "moment";
import SMSForm from "./QRForms/SMSForm";
import EmailForms from "./QRForms/EmailForms";
import PhoneForm from "./QRForms/PhoneForm";
import FaceTimeForm from "./QRForms/FaceTimeForm";
import LocationForm from "./QRForms/LocationForm";
import WifiForm from "./QRForms/WifiForm";
import EvenetForm from "./QRForms/EvenetForm";
import PaymentForm from "./QRForms/PaymentForm";
import GoogleReview from "./QRForms/ReviewForm";
import DotOption from "./QRForms/qrstyle/DotOption";
import BackgroundOptions from "./QRForms/qrstyle/BackgroundOptions";
import Mediaoptions from "./QRForms/qrstyle/Mediaoptions";

const QrGenerator = () => {
  const [type, setType] = useState("");
  const [form] = Form.useForm();

  const [generate_qr, set_generate_qr] = useState(false);
  const [dummy, setDummy] = useState(false);

  const [loading, setLoading] = useState(false);

  const location = useLocation();

  const [businessImages, setBusinessImages] = useState({
    bannerImage: "",
    profileImage: "",
  });

  const [qr_images, setQrImages] = useState("");

  let initiaStyles = {
    dotsOptions: {
      type: "sqaure",
      color: "#000",
      color_type: "single",
      gradient: {
        type: "linear",
        rotation: 0,
        colorStops: [
          {
            offset: 0,
            color: "#000",
          },
          {
            offset: 1,
            color: "#000",
          },
        ],
      },
    },
    backgroundOptions: {
      color: "#fff",
      color_type: "single",
      gradient: {
        type: "linear",
        rotation: 0,
        colorStops: [
          {
            offset: 0,
            color: "#fff",
          },
          {
            offset: 1,
            color: "#fff",
          },
        ],
      },
    },
    image: "",
  };

  const [qrstyle, setQrStyle] = useState(initiaStyles);

  useEffect(() => {
    if (_.get(location, "state.type", "")) {
      let formData = _.get(location, "state.formData", "");
      if (_.get(location, "state.type", "") === "Business") {
        setBusinessImages((pre) => ({
          ...pre,
          bannerImage: JSON.parse(formData).business_banner_image,
          profileImage: JSON.parse(formData).business_profile_image,
        }));
      }
      setQrImages(JSON.parse(formData).qr_image);
      setQrStyle(JSON.parse(_.get(location, "state.qr_style", "")));
      setType({ name: _.get(location, "state.type", "") });

      form.setFieldsValue(JSON.parse(formData));
    }
  }, [_.get(location, "state.type", "")]);

  const userData = useSelector((data) => data);

  let base_URL = config.baseAPI;

  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [qrValue, setQrValue] = useState(
    "Welcome to Weboney QR Code Generator"
  );

  const [extra, setExtra] = useState({
    color: "#000",
    logo: "https://www.qrcodeweboney.in/static/media/BrandLogo.3034d39873eb6c75f35d.png",
  });

  const displayForm = () => {
    switch (type?.name) {
      case "Text": {
        return <TextForm />;
      }

      case "Whatsapp": {
        return <WhatsApp />;
      }

      case "Link": {
        return <LinkForm />;
      }
      case "Business": {
        return (
          <BusinessForm
            businessImages={businessImages}
            setBusinessImages={setBusinessImages}
            handleUpload={handleUpload}
            formData={form.getFieldsValue()}
            currentId={currentId}
            setCurrentId={setCurrentId}
            handleBusinessHoursChange={handleBusinessHoursChange}
            handleRemoveImage={handleRemoveImage}
            loading={loading}
            setLoading={setLoading}
          />
        );
      }
      case "SMS": {
        return <SMSForm />;
      }
      case "Email": {
        return <EmailForms />;
      }
      case "Phone": {
        return <PhoneForm />;
      }
      case "Facetime": {
        return <FaceTimeForm />;
      }
      case "Location": {
        return <LocationForm />;
      }
      case "Wifi": {
        return <WifiForm />;
      }
      case "Event": {
        return <EvenetForm />;
      }
      case "Payment": {
        return <PaymentForm />;
      }
      case "Google Review": {
        return <GoogleReview />;
      }
    }
  };

  const handleFinish = async (values) => {
    set_generate_qr(true);
    switch (type?.name) {
      case "Text":
        {
          setQrValue(values.text_value);
          return values.text_value;
        }
        break;
      case "Whatsapp": {
        let value;
        if (_.get(values, "wa_phone_number", "").startsWith("+")) {
          value = `https://wa.me/${values.wa_phone_number}?text=${values.wa_message}`;
        } else {
          value = `https://wa.me/+91${values.wa_phone_number}?text=${values.wa_message}`;
        }
        setQrValue(value);
        return value;
      }

      case "Link": {
        setQrValue(values.link_value);
        return values.link_value;
      }

      case "Business": {
        await handleVcardSave(values);
        return values;
      }

      case "SMS": {
        let value = `sms:${values.sms_phone_number}?body=${encodeURIComponent(
          values.sms_message
        )}`;
        setQrValue(value);
        return value;
      }
      case "Email": {
        let data = `mailto:${values.email_email_address}?subject=${values.email_subject_address}&body=${values.email_message}`;
        setQrValue(data);
        return data;
      }
      case "Phone": {
        let data = `tel:${values.phone_phone_number}`;
        setQrValue(data);
        return data;
      }
      case "Facetime": {
        let data = `facetime://${values.facetime_phone_number}`;
        setQrValue(data);
        return data;
      }
      case "Location": {
        let data = `https://www.google.com/maps/search/?api=1&query=${values.location_message
          .replaceAll(" ", "+")
          .replaceAll(",", "+")}`;
        setQrValue(data);
        return data;
      }
      case "Wifi": {
        let data = `WIFI:S:${values.wifi_name.trim()};T:WPA;P:${values.wifi_password.trim()};;`;
        setQrValue(data);
        return data;
      }
      case "Event": {
        let data = `https://www.google.com/calendar/render?action=TEMPLATE&text=${values.event_name}&location=${values.event_geo_location}&details=${values.event_notes}&dates=${values.event_start_date}/${values.event_end_date}&ctz=UTC`;
        setQrValue(data);
        return data;
      }
      case "Payment": {
        let data = `upi://pay?pa=${values.payment_upi_id}&pn=${"Dmeo"}&tn=${
          values.payment_note
        }&am=${values.payment_amount}&cu=INR`;
        setQrValue(data);
        return data;
      }
      case "Google Review": {
        setQrValue(values.google_review_link);
        return values.google_review_link;
      }
    }
  };

  const handleVcardSave = async (values, field) => {
    setLoading(true);
    let id = Date.now();
    let value = `https://www.qrcodeweboney.in//#/${values.qr_name}/${id}`;
    values.qr_image = qr_images;
    try {
      values.business_banner_image = businessImages.bannerImage;
      values.business_profile_image = businessImages.profileImage;
      if (businessImages.bannerImage === "") {
        return message.error("Plz Upload Banner Image");
      }
      if (businessImages.profileImage === "") {
        return message.error("Plz Upload profile Image");
      }
      const formData = {
        data: _.get(location, "state.data", ""),
        user_id: _.get(userData, "userData.value.id", ""),
        type: type.name,
        name: values.qr_name,
        formData: JSON.stringify(values),
        vcard_id: _.get(location, "state.vcard_id", ""),
        qr_style: JSON.stringify(qrstyle),
      };
      if (_.get(location, "state.type", "")) {
        formData.id = _.get(location, "state.id", "");
        await updateQRInstant(formData);
        successNotification("QR successfully Updated");
        if (_.get(location, "state", "")) {
          location.state = null;
        }
        setLoading(false);
        return navigate("/dashboard/saved_qr");
      }
      formData.vcard_id = `${id}`;
      formData.data = value;
      await saveQRInstant(formData);
      successNotification("QR successfully Saved");
      setQrImages("");
      return navigate("/dashboard/saved_qr");
    } catch (err) {
    } finally {
      setLoading(false);
    }
    setQrValue(value);
    if (field != "save") {
      successNotification("Vcard successfully Generated");
    } else {
      return value;
    }
  };

  const handleCancel = () => {
    setQrValue("Welcome to Weboney QR Code Generator");
    form.resetFields();
    setQrStyle(initiaStyles);
    setType("");
    set_generate_qr(false);
  };

  const handleSaveQR = async () => {
    try {
      setLoading(true);
      let values = form.getFieldsValue();
      const newValue = await handleFinish(values);
      values.qr_image = qr_images;
      const formData = {
        data: newValue,
        user_id: _.get(userData, "userData.value.id", ""),
        type: type.name,
        name: values.qr_name,
        formData: JSON.stringify(values),
        qr_style: JSON.stringify(qrstyle),
      };

      if (_.get(location, "state.type", "")) {
        formData.id = _.get(location, "state.id", "");
        await updateQRInstant(formData);
        successNotification("QR successfully Updated");
        if (_.get(location, "state", "")) {
          location.state = null;
        }
        return navigate("/dashboard/saved_qr");
      }
      await saveQRInstant(formData);
      setLoading(false);
      setQrImages("");
      successNotification("QR successfully Saved");
      return navigate("/dashboard/saved_qr");
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const [currentId, setCurrentId] = useState(false);

  const handleBusinessHoursChange = (value, index) => {
    form.getFieldsValue().business_hours[index].status = value;
    setDummy(!dummy);
  };

  const handleRemoveImage = (field, id) => {
    try {
      if (field === "business_banner_image") {
        setBusinessImages((pre) => ({
          ...pre,
          bannerImage: "",
        }));
      } else if (field === "business_profile_image") {
        setBusinessImages((pre) => ({
          ...pre,
          profileImage: "",
        }));
      } else if (field === "services") {
        form.getFieldsValue().services[id].img = "none";
        setDummy(!dummy);
      } else if (field === "testimonials") {
        form.getFieldsValue().testimonials[id].img = "none";
        setDummy(!dummy);
      } else if (field === "qr_logo") {
        setQrStyle((prevState) => ({
          ...prevState,
          image: "",
        }));
        setQrImages("");
      }
    } catch (err) {}
  };

  const initialValues = {
    social_media: [
      { social: "instagram", to: "" },
      { social: "twitter", to: "" },
      { social: "facebook", to: "" },
      { social: "youtube", to: "" },
      { social: "linkedin", to: "" },
    ],
    services: [
      {
        img: "none",
        name: "",
        description: "",
      },
    ],
    testimonials: [
      {
        img: "none",
        name: "",
        review: "",
      },
    ],
    business_hours: [
      {
        day: "Mon",
        from: "09:00",
        to: "19:00",
        status: true,
      },
      {
        day: "Tue",
        from: "09:00",
        to: "19:00",
        status: true,
      },
      {
        day: "Wed",
        from: "09:00",
        to: "19:00",
        status: true,
      },
      {
        day: "Thu",
        from: "09:00",
        to: "19:00",
        status: true,
      },
      {
        day: "Fri",
        from: "09:00",
        to: "19:00",
        status: true,
      },
      {
        day: "Sat",
        from: "09:00",
        to: "19:00",
        status: true,
      },
      {
        day: "Sun",
        from: "09:00",
        to: "19:00",
        status: true,
      },
    ],
  };

  useEffect(() => {
    setLoading(true);
    if (type?.name === "Business") {
      setLoading(true);
      form.getFieldsValue().qr_name = form.getFieldsValue().qr_name;
      setDummy(!dummy);
      setLoading(false);
    }
    setLoading(false);
  }, [type?.name, _.get(location, "state.type", "")]);

  // const resolvethis = (value, field, key = 0) => {
  //   try {
  //     if (field === "services") {
  //       form.getFieldsValue().services[key].img = "none";
  //     } else if (field === "testimonials") {
  //       form.getFieldsValue().testimonials[key].img = "none";
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  const handleUpload = async (value, field, key) => {
    let file = _.get(value, "file.originFileObj", "");

    setLoading(true);

    if (field === "qr_logo") {
      if (!["image/webp"].includes(file.type)) {
        message.error(`
          Unsupported file type. Only webpg are allowed for upload.`);
        setLoading(false);
        return setDummy(!dummy);
      } else if (file.size > 1000000) {
        message.error(`The size of the image must not exceed 1 MB`);
        setLoading(false);
        return setDummy(!dummy);
      }
    }

    if (
      !["image/jpg", "image/jpeg", "image/png", "image/webp"].includes(
        file.type
      )
    ) {
      message.error(`
        Unsupported file type. Only PNG, JPEG, and JPG are allowed for upload.`);
      setLoading(false);
      return setDummy(!dummy);
    } else if (file.size > 4000000) {
      message.error(`The size of the image must not exceed 4 MB`);
      setLoading(false);
      return setDummy(!dummy);
    } else {
      try {
        setLoading(true);

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = async (result) => {
          let formdata = {
            service_image: result.target.result,
          };
          const result2 = await handleImageUpload(formdata);
          if (field === "business_banner_image") {
            setBusinessImages((pre) => ({
              ...pre,
              bannerImage: _.get(result2, "data.path", ""),
            }));
          } else if (field === "business_profile_image") {
            setBusinessImages((pre) => ({
              ...pre,
              profileImage: _.get(result2, "data.path", ""),
            }));
          }
          if (field === "services") {
            form.getFieldsValue().services[key].img = _.get(
              result2,
              "data.path",
              ""
            );
          } else if (field === "testimonials") {
            form.getFieldsValue().testimonials[key].img = _.get(
              result2,
              "data.path",
              ""
            );
          }
          if (field === "qr_logo") {
            setQrStyle((prevState) => ({
              ...prevState,
              image: result.target.result,
            }));
            setQrImages(`${base_URL}/${_.get(result2, "data.path", "")} `);
          }
          setDummy(!dummy);
        };
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="!w-full gap-y-6 py-2 flex flex-wrap  gap-10 justify-start px-5 !font-Poppins">
      <div
        className={`flex items-center shadow gap-x-2 bg-white w-full h-[50px] rounded ${
          type?.name ? "hidden" : "block"
        }`}
      >
        <img src={QR} alt="" className="!w-[50px]" />
        <h1 className="text-2xl font-bold up">Generate QR</h1>
      </div>

      {type?.name ? (
        <Skeleton loading={loading} className="!w-full">
          <Form
            onChange={() => {
              setDummy(!dummy);
            }}
            initialValues={initialValues}
            form={form}
            layout="vertical"
            className="!w-full"
            onFinish={handleFinish}
          >
            <div className="!w-full bg-white z-50 flex items-center   justify-center">
              <div className="!w-full flex items-center !h-[50px]  rounded-lg justify-between">
                <div className="text-2xl flex items-center gap-x-1">
                  <IoIosArrowBack
                    onClick={handleCancel}
                    className="!cursor-pointer"
                  />
                  <img src={type.icon} className="size-[15%]" />
                  <p className="!flex !items-center ">
                    {type?.name != "Business"
                      ? type?.name
                      : type?.name + "Card"}
                  </p>
                </div>
                <div className="flex items-center gap-x-2 px-10">
                  <Button
                    htmlType="submit"
                    className="!bg-primaryColor !text-white !border-none !h-[30px] !w-[150px]"
                  >
                    {_.get(location, "state.type", "")
                      ? type?.name === "Business"
                        ? "Update"
                        : "Generate"
                      : "Generate"}
                    &nbsp;QR
                  </Button>

                  {type?.name === "Business" ? (
                    ""
                  ) : (
                    <Button
                      disabled={!generate_qr}
                      onClick={handleSaveQR}
                      className={`${
                        generate_qr ? "!bg-primaryColor" : "!bg-gray-200"
                      }  !text-white !border-none !h-[30px] !w-[150px]`}
                    >
                      {_.get(location, "state.type", "") ? "Update" : "Save"}
                      &nbsp;QR
                    </Button>
                  )}
                </div>
              </div>
            </div>
            <div className="flex ">
              {/* form */}
              <div
                className={`${
                  type?.name === "Business" ? "w-full" : "w-[70%]"
                } pt-4`}
              >
                <div
                  className={` bg-white ${
                    type.name === "Business" ? "!w-full" : "!w-full"
                  } p-4 shadow-inner rounded-lg !h-fit `}
                >
                  <Form.Item
                    name="qr_name"
                    label="QR Name"
                    rules={[basicRules("Enter QR Name")]}
                  >
                    <Input
                      placeholder="Enter qr Name"
                      className="!w-[400px] !shadow-inner"
                    />
                  </Form.Item>
                  <div className="pb-2">{displayForm()}</div>
                </div>
                <div className="mt-2 w-full min-h-[100px] py-10 bg-white p-4">
                  <Collapse
                    defaultActiveKey={["1", "2", "3"]}
                    items={[
                      {
                        label: "Dot Options",
                        key: "1",
                        children: (
                          <DotOption
                            setQrStyle={setQrStyle}
                            qrstyle={qrstyle}
                          />
                        ),
                      },
                      {
                        label: "Background Color",
                        key: "2",
                        children: (
                          <BackgroundOptions
                            setQrStyle={setQrStyle}
                            qrstyle={qrstyle}
                          />
                        ),
                      },
                      {
                        label: "Logo",
                        key: "3",
                        children: (
                          <Mediaoptions
                            handleUpload={handleUpload}
                            setQrStyle={setQrStyle}
                            qrstyle={qrstyle}
                            handleRemoveImage={handleRemoveImage}
                            qr_images={qr_images}
                          />
                        ),
                      },
                    ]}
                  />
                </div>
              </div>

              {/* qr */}
              {type?.name === "Business" ? (
                ""
              ) : (
                <div className="w-[30%]">
                  <div className="pt-5 fixed px-10">
                    <CustomQRCode
                      formData={form.getFieldsValue()}
                      qrValue={qrValue}
                      extra={extra}
                      setExtra={setExtra}
                      qrstyle={qrstyle}
                      qr_images={qr_images}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="!px-10 !pb-2">
              {type?.name === "Business" ? (
                <Button
                  block
                  htmlType="submit"
                  className="!bg-primaryColor !text-white !border-none !h-[30px] !w-[150px]"
                >
                  {_.get(location, "state.type", "")
                    ? type?.name === "Business"
                      ? "Update"
                      : "Generate"
                    : "Generate"}
                  &nbsp;QR
                </Button>
              ) : (
                ""
              )}
            </div>
          </Form>
        </Skeleton>
      ) : (
        QRTypes.map((res, index) => {
          return (
            <div
              className="card w-[22%] bg-base-100 shadow-xl relative cursor-pointer hover:brightness-125"
              onClick={() => {
                setType(res);
              }}
            >
              <figure className="px-10 pt-10">
                <img
                  src={res.icon}
                  alt="Shoes"
                  className="rounded-xl w-[150px] h-[150px]"
                />
              </figure>
              <div className="card-body items-center text-center">
                <h2 className="card-title">
                  {type?.name === "Business"
                    ? _.get(res, "name", "")
                    : _.get(res, "name", "") + " Card"}
                </h2>
                <p>Simple and clear text embedded into a QR code</p>
              </div>
            </div>
            // <div
            //   key={index}
            //   className="w-[20%] min-h-[250px] relative flex items-center py-10 bg-white flex-col rounded-lg  gap-y-2   cursor-pointer"
            // >
            //   <img src={res.icon} />
            //   <div className="text-xl font-Righteous !font-normal pt-2">
            //     {_.get(res, "name", "")}
            //   </div>
            //   <button

            //     className="absolute  bottom-10 min-w-[100px] hover:bg-white hover:shadow-2xl  rounded-lg h-[30px] center_div px-3 py-2 border  tracking-wide"
            //   >
            //     Generate
            //   </button>
            // </div>
          );
        })
      )}
    </div>
  );
};

export default QrGenerator;
